import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StatLabel,
} from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_term = '490new';
const utm_campaign = '490new';
const utm_medium = '490new';

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [city, setCity] = useState<string>('');

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'rozygrysh');
  }, []);

  //* ==================
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        city,
        name: username,
        phone: '+7' + phone,
        utm_term,
        utm_source: window.location.hostname,
        utm_campaign,
        utm_medium,
      }).then(async () => {
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          <br />
          ❗️Не упустите возможность воспользоваться самым шикарным предложением этого года! <br />
          Только до 30 сентября включительно в Миссис Лазер можно воспользоваться лазерной эпиляции любой зоны на любом
          аппарате всего за 490 рублей! <br /> <br />
          Почему у нас? <br />
          ✅Мы сеть клиник лазерной эпиляции с медицинскими лицензиями - мы несем ответственность за Вас! <br />
          ✅У нас работают высокопрофессиональные мастера! <br />
          ✅Используется самое передовое дорогостоящее оборудование на рынке одобренное мин. здравом! <br /> <br />
          ❤️Получите максимальный комфорт и уверенность в себе за лучшую цену!
          <br />
          <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
                <option value='Другой город'>Другой город</option>
              </Select>
            </InputGroup>
          </Stack>

          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}

          <Button
            //disabled={city === "" || friend_phone === "" || friend_name === "" || phone === "" || username === ""}
            onClick={() => {
              if (city === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Забрать подарок
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
